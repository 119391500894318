// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"

global.$ = require("jquery");

require("jquery");
require("jquery-ui");

Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

window.bootstrap = require('js/services/bootstrap-533/js/bootstrap.bundle.js');

require("../js/angular/angular");
require("../js/angular/angular-resource");
require("../js/angular/angular-sanitize");

require("js/services/jquery.magnific-popup");

import '../js/social_network/fluidbook_without_bootstrap.js' 
import '../js/index/turnjs' 

require("js/welcome_2024.js");

require("../js/index/controllers/home_ctrl.js");



$(function () {
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  
});

